/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from 'react';
import { withRouter } from 'react-router';
import { useHistory } from "react-router-dom";
import { Progress, PageHeader, Row, Col, Typography } from 'antd'; 
import Icon from '../Icon';
import Api from '../../services/api';

import './Header.less';

const HEADERS = {
  'participant.new': { 
    title: 'Cadastro do participante', 
    description: 'Olá! Nessa página você irá preencher seus dados para participar do Evento XXXX.' 
  },
  'participant.address': { 
    title: 'Cadastro do endereço', 
    description: 'Olá! Nessa página você irá preencher seus dados para participar da Turma XXXX.' 
  },
  'participant.attachment': { 
    title: 'Documentos', 
    description: 'Olá! Nessa página você irá preencher seus dados para participar da Turma XXXX.' 
  },
}

const { Title, Text } = Typography;

function Header({ location, routes }) {
  const history = useHistory();
  const inputRef = useRef();
  const [offsetTop, setOffsetTop] = useState(0);
  const [mapping, setMapping] = useState({});
  
  // eslint-disable-next-line no-unused-vars
  const [_, _type, linkId, group, action] = location.pathname.split('/');

  const getLink = async () => {
    const response = await Api.get(linkId);
  
    if (!response?.data) return;
    setMapping(response.data);
  }
  
  const base =  100 / (routes?.length || 1);
  const percent = routes.findIndex(({ path }) => {
    const [_Original, _typeOriginal, linkIdOriginal, ...routeOriginal] = path?.split('/')
    const [_, _type, linkId, ...route] = location.pathname?.split('/')
    return routeOriginal.join('/') === route.join('/');
  }) || 0;
  
  const onBack = ()=> history.goBack();
  
  const scrollHandler = () => {
    const { top } = inputRef.current.getBoundingClientRect();
    setOffsetTop(top);
  };
  
  useEffect(() => {
    scrollHandler();
    getLink();
    window.addEventListener('scroll', scrollHandler, true);
    return () => window.removeEventListener('scroll', scrollHandler, true);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  
  if (!mapping) return;

  const message = HEADERS[`${group || 'participant'}.${action || 'new'}`];

  return (
    <div ref={inputRef}>
      {(offsetTop >= 0) && (
        <PageHeader
          className="header-fixed"
          key="pageheader"
          onBack={onBack}
          backIcon={percent === 0 ? null : <Icon type="arrow-left" />}
          title={(
            <Row type="flex" gutter={36} justify="space-between" align="middle">
              <Col span={4}>
                <div className="logo_avatar" />
              </Col>
              <Col span={20}>
                <Title level={4}>
                  {message?.title}
                </Title>
                <Text ellipsis type="secondary" style={{ fontSize: 16 }}>
                  {(message?.description || '').replace('XXXX', mapping?.name).replace('YYYY', mapping?.company?.name)}<br/>
                  Qualquer dúvida entre em contato conosco!
                </Text>
              </Col>
            </Row>
          )}
        >
          <Progress
            strokeColor={{
              '0%': '#4CC39B',
              '100%': '#4CC39B',
            }}
            trailColor="#f2f2f2"
            className="progress-level"
            percent={(percent + 1) * base}
            size="small"
            showInfo={false} 
          />
        </PageHeader>
      )}
    </div>
  )
}

export default withRouter(Header);