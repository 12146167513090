import React, { Suspense, useEffect } from 'react';
import { Loading, Layout, Header, LayoutContent, Routing } from './components';
import { notification } from 'antd';
import { BrowserRouter, Switch } from 'react-router-dom';
import numeral from 'numeral';
import 'numeral/locales'; 
import { DEFAULT } from './constants/proporsal';
import { ROUTES } from './config/routes';
import useLocalStorage from './hooks/useLocalStorage';

import './App.less';

const Page404 = React.lazy(() => import('./pages/Page404'));
numeral.locale('pt-br');

function App() {
  const [data, setData] = useLocalStorage('mappingData', DEFAULT)

  useEffect(() => {
    openNotification('Carregado com sucesso!', 'Todos os dados foram recuperados ;D');
    setData({ ...data });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const openNotification = (message = 'Salvo com sucesso!', description) => {
    const args = {
      message,
      description,
    };
    notification.open(args);
  };

  const onChange = (field, event) => setData({ ...data, [field]: { ...data[field], ...event } });  
  const onChangeParticipant = (event) => onChange('participant', event);
  const onReset = () => setData(DEFAULT);

  return (
    <BrowserRouter>
      <Suspense fallback={<Loading />}>
        <Layout className="App">
          <Header
            routes={ROUTES}
            data={data}
          />

          <LayoutContent>
            <Switch>
              {ROUTES.map(({ path, title, component }) => (
                <Routing
                  key={path}
                  path={path}
                  component={component}
                  title={title}
                  exact={true}
                  struct={{ 
                    data, 
                    onChangeParticipant,
                    onReset,
                    openNotification
                  }}
                />
              ))}
              <Routing path="*" component={Page404} />
            </Switch>
          </LayoutContent>
        </Layout>
      </Suspense>
    </BrowserRouter>
  )
}

export default App;
