import axios from 'axios';
const { BACKEND_URL, DEBUG } = process.env;

axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';
const Api = axios.create({
  baseURL: BACKEND_URL,
  timeout: 0,
  withCredentials: true,
  mode: 'no-cors',
  crossDomain: true,
  credentials: 'same-origin',
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
    'Allow-Control-Allow-Origin': '*',
    'Access-Control-Allow-Credentials': true,
    'Access-Control-Allow-Headers': 'x-requested-with'
  }
});

const get = async (linkId) => {
  if (!linkId) return;
  return !DEBUG && Api.get(`/link/mapping/${linkId}`);
}

const participant = async (linkId, data) => {
  if (!linkId || !data) return;
  return !DEBUG && Api.post(`/link/mapping/${linkId}/participant`, data);
};

const create = async (linkId, data) => {
  if (!linkId || !data) return;
  return !DEBUG && Api.post(`/link/mapping/${linkId}/create`, data);
};

const valid = async (linkId, taxNumber) => {
  if (!linkId || !taxNumber) return;
  return !DEBUG && Api.get(`/link/mapping/${linkId}/contact/${taxNumber}/valid`);
}

const Http = {
  participant,
  create,
  get,
  valid
};

export default Http;