import { lazy } from 'react';

const ParticipantValid = lazy(() => import('../pages/ParticipantValid'));
const ParticipantNew = lazy(() => import('../pages/ParticipantNew'));
const ParticipantAddress = lazy(() => import('../pages/ParticipantAddress'));
const ParticipantAttachment = lazy(() => import('../pages/ParticipantAttachment'));
const Result = lazy(() => import('../pages/Result'));

export const ROUTES = [
  { path: '/mapping/:linkId', component: ParticipantValid, title: 'Cadastro do participante' },
  { path: '/mapping/:linkId/participant/new', component: ParticipantNew, title: 'Cadastro do participante' },
  { path: '/mapping/:linkId/participant/address', component: ParticipantAddress, title: 'Cadastro do participante' },
  { path: '/mapping/:linkId/participant/attachment', component: ParticipantAttachment, title: 'Cadastro do participant' },
  { path: '/mapping/:linkId/result', component: Result, title: 'Concluído o cadastro' },
];
